import './styles.css';

import { Link, useNavigate, useParams } from '@remix-run/react';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import chunk from 'lodash/chunk';
import pluralize from 'pluralize';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import Slider, { type Settings } from 'react-slick';

import { MediaFormatVersion } from '@lp-lib/media';

import { useMemoriesAnalytics } from '../../analytics/memories';
import logo from '../../assets/img/lp-3d-logo.png';
import { ArrowNext, ArrowPrev } from '../../components/Game/GameCenter';
import { GamePackCover } from '../../components/Game/Utilities';
import { GameCoverClip } from '../../components/GamePackCover/GamePackCoverPres';
import { GlobalLoading } from '../../components/GlobalLoading';
import { CalendarColorfulIcon } from '../../components/icons/CalendarIcon';
import {
  type JoyCaptureRenderer,
  useJoyCaptureRenderer,
} from '../../components/JoyCapture';
import { Loading } from '../../components/Loading';
import { useIsUserLoaded, useUser } from '../../components/UserContext';
import { useFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useQueryParam } from '../../hooks/useQueryParam';
import { apiService } from '../../services/api-service';
import {
  hasStaffFlag,
  type LeaderboardTeam,
  RoleUtils,
  type ScoreboardEntry,
  type SessionMemories,
  SessionMode,
} from '../../types';
import { getStaticAssetPath } from '../../utils/assets';
import { xDomainifyUrl } from '../../utils/common';
import { MediaUtils } from '../../utils/media';
import { MEMORY_TEMPLATES } from './Templates';
import { GroupPhoto } from './Templates/GroupPhoto';
import {
  PairingLeaderboardPolaroid,
  ScoreboardPolaroid,
} from './Templates/Layout2';
import { useSessionGroupPhoto } from './useSessionGroupPhoto';
import { useSessionMemories } from './useSessionMemories';

const bgMemories = getStaticAssetPath('images/bg-memories.png');
const polaroidCamera = getStaticAssetPath('images/polaroid-camera.png');
const trophy = getStaticAssetPath('images/trophy.png');
const photoAlbum = getStaticAssetPath('images/photo-album.png');

const MAX_GROUP_PHOTO_SIZE = 120;

async function download(url: string | null | undefined) {
  if (!url) return;

  const blob = await fetch(url).then((response) => response.blob());
  const blobURL = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = blobURL;
  a.style.display = 'none';
  a.download = `luna-park-souvenir-${Date.now()}.gif`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

function DownloadButton(props: {
  templateId: string;
  session: SessionMemories;
  teamId?: string;
}): JSX.Element {
  const analytics = useMemoriesAnalytics();
  const [downloading, setDownloading] = useState(false);
  const { groupPhoto, isLoading, error } = useSessionGroupPhoto(
    props.session.id
  );
  const useParticipantTeamId = useFeatureQueryParam(
    'memories-use-participant-team-id'
  );

  const onClick = useLiveCallback(async () => {
    setDownloading(true);
    try {
      if (props.templateId === 'GroupPhoto') {
        // special handling for the group photo...
        if (!groupPhoto) return;
        await download(xDomainifyUrl(groupPhoto.url, 'memories-download'));
      } else {
        // otherwise, we need to render the template first...
        if (!props.teamId) return;
        const resp = await apiService.session.renderMemoriesTemplate({
          templateId: props.templateId,
          sessionId: props.session.id,
          teamId: props.teamId,
          useParticipantTeamId,
        });
        await download(resp.data.url);
      }
      analytics.trackMemoriesSouvenirDownloaded({
        templateId: props.templateId,
        isPairingGame: props.session.pairingId !== null,
        isOndGame: props.session.mode === SessionMode.OnDemand,
      });
    } finally {
      setDownloading(false);
    }
  });

  const disabled =
    downloading ||
    (props.templateId === 'GroupPhoto' &&
      Boolean(groupPhoto === null || isLoading || error));

  return (
    <button
      type='button'
      className='btn btn-primary h-9 w-50 flex justify-center items-center'
      disabled={disabled}
      onClick={onClick}
    >
      {downloading ? <Loading text='' /> : 'Download'}
    </button>
  );
}

function SouvenirPicker(props: {
  session: SessionMemories;
  renderer: JoyCaptureRenderer;
  adminSelectedTeam?: ScoreboardEntry;
}): JSX.Element | null {
  const user = useUser();
  const { scoreboard } = props.session;

  const maybeParticipant = useMemo(() => {
    return Object.values(scoreboard?.participants ?? {}).find(
      (p) => p.uid === user.id
    );
  }, [user.id, scoreboard?.participants]);

  const maybeTeamEntry = useMemo(() => {
    if (maybeParticipant === undefined) return undefined;
    return (scoreboard?.scores ?? []).find(
      (s) => s.teamMembers.indexOf(maybeParticipant.id) > -1
    );
  }, [scoreboard?.scores, maybeParticipant]);

  const team =
    RoleUtils.isAdmin(user) && props.adminSelectedTeam
      ? props.adminSelectedTeam
      : maybeTeamEntry;

  return (
    <TeamSouvenirPicker
      session={props.session}
      renderer={props.renderer}
      team={team}
    />
  );
}

function TeamSouvenirPicker(props: {
  session: SessionMemories;
  renderer: JoyCaptureRenderer;
  team?: ScoreboardEntry;
}): JSX.Element | null {
  const analytics = useMemoriesAnalytics();
  const groupPhotoEnabled = useFeatureQueryParam('memories-group-photo');
  const { scoreboard, gamePack } = props.session;

  const { groupPhoto, isLoading } = useSessionGroupPhoto(props.session.id);

  const maybeTeamMembers = useMemo(() => {
    if (props.team === undefined || scoreboard?.participants === undefined)
      return undefined;
    return props.team.teamMembers.map((t) => scoreboard.participants[t]);
  }, [props.team, scoreboard?.participants]);

  const [currentSlide, setCurrentSlide] = useState(0);

  const sliderSettings: Settings = useMemo(() => {
    const styles = {
      arrow: 'text-white z-10',
    };
    return {
      className: 'w-full h-full',
      adaptiveHeight: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <ArrowNext styles={styles} />,
      prevArrow: <ArrowPrev styles={styles} />,
      beforeChange(_currentSlide: number, nextSlide: number) {
        // note(falcon): afterChange is not called consistently. beforeChange is more reliable.
        setCurrentSlide(nextSlide);
      },
    };
  }, [setCurrentSlide]);

  const memoryTemplates = useMemo(() => {
    const numTeams = (scoreboard?.scores ?? []).length;
    const numPlayers = Object.keys(scoreboard?.participants ?? {}).length;
    const templates = [...MEMORY_TEMPLATES];
    if (
      groupPhotoEnabled &&
      groupPhoto &&
      numTeams > 1 &&
      numPlayers < MAX_GROUP_PHOTO_SIZE &&
      // not supported for pairings...this should be caught by the above check, but just in case...
      props.session.pairingId === null
    ) {
      templates.unshift({
        id: 'GroupPhoto',
        component: GroupPhoto,
      });
    }
    return templates;
  }, [
    groupPhotoEnabled,
    groupPhoto,
    scoreboard?.scores,
    scoreboard?.participants,
    props.session.pairingId,
  ]);

  const slides = useMemo(() => {
    if (memoryTemplates.length === 0) return [];

    const team = props.team;
    if (team === undefined || maybeTeamMembers === undefined) {
      // look to see if we have a group photo template.
      const groupPhotoTemplate = memoryTemplates.find(
        (t) => t.id === 'GroupPhoto'
      );
      if (groupPhotoTemplate !== undefined) {
        // if we have one, we'll render it for anyone to see/download.
        return [
          <div key='GroupPhoto' className='w-full h-full'>
            <div className='flex items-center justify-center'>
              <GroupPhoto
                sessionId={props.session.id}
                background={
                  props.session.gamePack?.marketingSettings
                    ?.joyCaptureBackground?.media?.url || null
                }
              />
            </div>
          </div>,
        ];
      }
      return [];
    }

    return memoryTemplates.map((template) => {
      const Template = template.component;
      return (
        <div key={template.id} className='w-full h-full'>
          <div className='flex items-center justify-center'>
            <Template
              sessionId={props.session.id}
              teamId={team.teamId}
              teamName={team.teamName}
              teamMembers={maybeTeamMembers}
              gamePack={gamePack}
              renderer={props.renderer}
            />
          </div>
        </div>
      );
    });
  }, [
    memoryTemplates,
    props.team,
    props.session.id,
    props.session.gamePack?.marketingSettings?.joyCaptureBackground?.media?.url,
    props.renderer,
    maybeTeamMembers,
    gamePack,
  ]);

  const downloadButton = useMemo(() => {
    const currentTemplate = memoryTemplates[currentSlide];
    if (!currentTemplate) return null;

    return (
      <DownloadButton
        templateId={currentTemplate.id}
        session={props.session}
        teamId={props.team?.teamId}
      />
    );
  }, [currentSlide, memoryTemplates, props.session, props.team?.teamId]);

  useLayoutEffect(() => {
    const currentTemplate = memoryTemplates[currentSlide];
    if (slides.length === 0 && currentTemplate) return;
    analytics.trackMemoriesSouvenirPickerViewed({
      templateId: currentTemplate.id,
      isPairingGame: props.session.pairingId !== null,
      isOndGame: props.session.mode === SessionMode.OnDemand,
    });
  }, [
    slides,
    memoryTemplates,
    currentSlide,
    analytics,
    props.session.pairingId,
    props.session.mode,
  ]);

  if (slides.length === 0) return null;

  return (
    <div className='w-full flex flex-col items-center gap-10'>
      <div className='flex items-center gap-2'>
        <img src={polaroidCamera} alt='Camera' className='w-16' />
        <div className='flex flex-col items-center'>
          <div className='text-center text-white font-bold text-2xl'>
            Commemorate Your Game!
          </div>
          <div className='text-center text-white text-sms tracking-[0.15px]'>
            Customize and download your memory!
          </div>
        </div>
        <div className='w-16' />
      </div>

      <div className='w-[820px] h-[405px]'>
        {isLoading ? (
          <div className='w-full h-full flex items-center justify-center'>
            <Loading text='' />
          </div>
        ) : (
          <Slider {...sliderSettings}>{slides}</Slider>
        )}
      </div>

      {!isLoading && downloadButton}
    </div>
  );
}

function SessionSummary(props: { session: SessionMemories }): JSX.Element {
  const { organization, gamePack, startedAt } = props.session;

  const orgLogoSrc = MediaUtils.PickMediaUrl(organization?.logo, {
    priority: [MediaFormatVersion.SM],
  });

  return (
    <div className='flex-col gap-3.5 hidden lg:flex max-w-51'>
      {organization && (
        <div className='flex items-center justify-end gap-4 text-white font-bold tracking-[0.15px]'>
          {orgLogoSrc && (
            <div className='flex-none w-10 h-10 rounded-md overflow-hidden'>
              <img
                src={orgLogoSrc}
                alt='Organization Logo'
                className='w-full h-full object-cover'
              />
            </div>
          )}
          {organization.name}
        </div>
      )}
      {gamePack && (
        <div className='flex justify-end'>
          <div className='w-45'>
            <GamePackCover
              pack={gamePack}
              size='medium'
              outerClassName='shadow-xl'
            />
          </div>
        </div>
      )}
      <div className='text-white font-bold tracking-[0.15px] text-right'>
        {gamePack && <div>{gamePack.name}</div>}
        <div>{format(parseISO(startedAt), 'MMMM d, yyyy')}</div>
      </div>
    </div>
  );
}

function ScheduleGameButton(props: { session: SessionMemories }): JSX.Element {
  const analytics = useMemoriesAnalytics();

  const campaign = useQueryParam('utm_campaign');
  const medium = useQueryParam('utm_medium');
  const handleClick = () => {
    analytics.trackMemoriesScheduleButtonClicked({
      sessionId: props.session.id,
      gamePackId: props.session.gamePack?.id,
      gamePackName: props.session.gamePack?.name,
      isPairingGame: props.session.pairingId !== null,
      campaign,
      medium,
    });
  };

  return (
    <Link
      to={'/events/create'}
      className='
        w-64 bg-layer-002 rounded-xl border border-secondary p-2
        flex items-center gap-2
      '
      onClick={handleClick}
      target='_blank'
    >
      <CalendarColorfulIcon className='w-16 h-16' />

      <div>
        <p className='text-primary text-sms text-bold'>Be a Hero!</p>
        <p className='text-white text-sms'>
          Schedule your next team experience today!
        </p>
      </div>
    </Link>
  );
}

function Album(props: {
  title: string;
  subtitle: string | React.ReactNode;
  slides: React.ReactNode[];
  type: 'Scoreboard' | 'Pairs Leaderboard';
  session: SessionMemories;
  img?: string;
}): JSX.Element | null {
  const { title, subtitle, slides } = props;
  const analytics = useMemoriesAnalytics();

  const chunkedSlides = useMemo(() => {
    if (slides.length === 0) return [];
    const chunks = chunk(slides, 6);
    return chunks.map((chunk, i) => {
      const shouldCenter = chunks.length === 1 && chunk.length < 3;
      return (
        <div key={i} className='py-6'>
          <div
            className={`
              ${
                shouldCenter
                  ? 'flex justify-center'
                  : 'grid grid-cols-2 justify-items-center'
              }
              gap-x-8.5 gap-y-8 w-130 mx-auto
              xl:grid-cols-3 xl:w-full xl:px-12
            `}
          >
            {chunk.map((slide, j) => {
              return (
                <div key={`${i}-${j}`} className='transform rotate-[-2deg]'>
                  {slide}
                </div>
              );
            })}
          </div>
        </div>
      );
    });
  }, [slides]);

  const handleSlideChange = useCallback(() => {
    analytics.trackMemoriesAlbumScrolled({
      type: props.type,
      isPairingGame: props.session.pairingId !== null,
      isOndGame: props.session.mode === SessionMode.OnDemand,
    });
  }, [analytics, props.session.mode, props.session.pairingId, props.type]);

  const sliderSettings: Settings = useMemo(() => {
    const styles = {
      arrow: 'text-white',
      disabled: 'hidden',
    };
    return {
      className: 'w-full',
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <ArrowNext styles={styles} />,
      prevArrow: <ArrowPrev styles={styles} />,
      beforeChange: handleSlideChange,
    };
  }, [handleSlideChange]);

  useEffect(() => {
    if (chunkedSlides.length === 0) return;
    analytics.trackMemoriesAlbumViewed({
      type: props.type,
      isPairingGame: props.session.pairingId !== null,
      isOndGame: props.session.mode === SessionMode.OnDemand,
    });
  }, [
    props.type,
    chunkedSlides,
    analytics,
    props.session.pairingId,
    props.session.mode,
  ]);

  if (chunkedSlides.length === 0) return null;

  return (
    <div className='w-full flex flex-col items-center gap-10'>
      <div className='flex items-center gap-1'>
        <img src={props.img ?? trophy} alt='Trophy' className='w-15' />
        <div className='flex flex-col items-center'>
          <div className='text-center text-white font-bold text-2xl'>
            {title}
          </div>
          <div className='text-center text-white text-sms tracking-[0.15px]'>
            {subtitle}
          </div>
        </div>
        <div className='w-15' />
      </div>

      <Slider {...sliderSettings}>{chunkedSlides}</Slider>
    </div>
  );
}

function ScoreboardAlbum(props: {
  session: SessionMemories;
  renderer: JoyCaptureRenderer;
  onClick?: (entry: ScoreboardEntry) => void;
}): JSX.Element | null {
  const { onClick, renderer, session } = props;
  const { scoreboard } = session;

  const slides = useMemo(() => {
    const scores = scoreboard?.scores ?? [];
    const participants = scoreboard?.participants ?? {};

    return scores
      .map((entry) => {
        const members = entry.teamMembers
          .map((t) => participants[t])
          .filter((t) => t !== undefined);
        const allStaff = members.every((m) => hasStaffFlag(m.name));

        const handleClick = () => {
          if (onClick) {
            onClick(entry);
          }
        };

        if (members.length === 0 || allStaff) return undefined;

        return (
          <div
            onClick={handleClick}
            className={onClick !== undefined ? 'cursor-pointer' : ''}
          >
            <ScoreboardPolaroid
              rank={entry.rank}
              score={entry.score}
              teamName={entry.teamName}
              teamMembers={members}
              renderer={renderer}
            />
          </div>
        );
      })
      .filter((s) => s !== undefined);
  }, [scoreboard?.scores, scoreboard?.participants, renderer, onClick]);

  const { title, subtitle, img } = useMemo(() => {
    const scores = scoreboard?.scores ?? [];
    const hasScores = scores.some((s) => s.score !== null);
    if (hasScores) {
      return {
        title: 'Scoreboard Album',
        subtitle: 'Great job everyone! Congrats to the winners!',
        img: trophy,
      };
    } else {
      return {
        title: 'Team Photo Album',
        subtitle: 'Great job everyone! Come back soon!',
        img: photoAlbum,
      };
    }
  }, [scoreboard?.scores]);

  return (
    <Album
      title={title}
      subtitle={subtitle}
      img={img}
      slides={slides}
      type='Scoreboard'
      session={session}
    />
  );
}

function PairsLeaderboardAlbum(props: {
  leaderboard: LeaderboardTeam[];
  renderer: JoyCaptureRenderer;
  session: SessionMemories;
  onClick?: (entry: LeaderboardTeam) => void;
}): JSX.Element | null {
  const { leaderboard, renderer, onClick } = props;

  const slides = useMemo(() => {
    let currentRank = 0;
    let currentRankScore = -Infinity;

    return leaderboard.map((entry, index) => {
      const handleClick = () => {
        if (onClick) {
          onClick(entry);
        }
      };

      const players = entry.players ?? [];
      const score = entry.score ?? 0;
      if (score !== currentRankScore) {
        currentRank = index + 1;
        currentRankScore = score;
      }

      return (
        <div
          onClick={handleClick}
          className={onClick !== undefined ? 'cursor-pointer' : ''}
        >
          <PairingLeaderboardPolaroid
            rank={currentRank}
            score={score}
            teamName={entry.teamName}
            teamMembers={players
              .map((p) => p.displayName.trim())
              .filter((n) => n.length > 0)
              .join(', ')}
            uids={players.map((p) => p.uid)}
            sessionId={entry.sessionId}
            renderer={renderer}
          />
        </div>
      );
    });
  }, [leaderboard, onClick, renderer]);

  return (
    <Album
      title='Leaderboard Album'
      subtitle={
        <span>
          Here are the top <strong>{slides.length}</strong>{' '}
          {pluralize('team', slides.length)} in your org for this round!
        </span>
      }
      slides={slides}
      type='Pairs Leaderboard'
      session={props.session}
    />
  );
}

function Layout(props: { children?: React.ReactNode }): JSX.Element {
  return (
    <div
      className='relative w-full h-full bg-no-repeat bg-cover bg-center overflow-y-scroll scrollbar'
      style={{ backgroundImage: `url('${bgMemories}')` }}
    >
      {props.children}
    </div>
  );
}

function MemoriesContainer(props: { sessionId: string }): JSX.Element {
  const user = useUser();
  const navigate = useNavigate();
  const isAdmin = RoleUtils.isAdmin(user);
  const { isLoading, session, pairingLeaderboard, error } = useSessionMemories(
    props.sessionId
  );
  const renderer = useJoyCaptureRenderer();
  const [selectedTeam, setSelectedTeam] = useState<ScoreboardEntry | undefined>(
    undefined
  );
  const stableSetSelectedTeam = useLiveCallback(
    (entry: ScoreboardEntry | undefined) => setSelectedTeam(entry)
  );
  const handleSelectPairsTeam = useLiveCallback((entry: LeaderboardTeam) => {
    if (entry.sessionId === props.sessionId) return;
    setSelectedTeam(undefined);
    navigate(`/sessions/${entry.sessionId}/memories`);
  });
  useEffect(() => {
    // when an admin views a pairing memories page, auto select the only team.
    const scores = session?.scoreboard?.scores;
    if (!pairingLeaderboard || !isAdmin || !scores || scores.length !== 1)
      return;
    setSelectedTeam(scores[0]);
  }, [pairingLeaderboard, isAdmin, session?.scoreboard?.scores]);

  if (isLoading && session === undefined) {
    return <GlobalLoading />;
  }

  if (error || session === undefined) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 403) {
        return (
          <Layout>
            <div className='absolute inset-0 flex items-center justify-center text-white text-lg font-medium'>
              You do not have access to view this page.
            </div>
          </Layout>
        );
      } else if (error.response?.status === 404) {
        return (
          <Layout>
            <div className='absolute inset-0 flex items-center justify-center text-white text-lg font-medium'>
              Memories are unavailable for this session.
            </div>
          </Layout>
        );
      }
    }

    return (
      <Layout>
        <div className='absolute inset-0 flex items-center justify-center text-white text-lg font-medium'>
          Something went wrong. Try refreshing.
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <GameCoverClip id='game-cover-clip' />

      <div className='fixed top-2 left-2.5 flex flex-col gap-6'>
        <Link to='/home'>
          <img src={logo} alt='Luna Park Logo' className='h-12' />
        </Link>
        {session.mode === SessionMode.OnDemand && (
          <ScheduleGameButton session={session} />
        )}
      </div>

      <div className='fixed top-5 right-5'>
        <SessionSummary session={session} />
      </div>

      <div className='mx-auto my-8.5 w-208 space-y-18'>
        <section>
          <SouvenirPicker
            session={session}
            renderer={renderer}
            adminSelectedTeam={isAdmin ? selectedTeam : undefined}
          />
        </section>

        <section>
          {pairingLeaderboard ? (
            <PairsLeaderboardAlbum
              leaderboard={pairingLeaderboard}
              renderer={renderer}
              session={session}
              // allows the admin to select a team and navigate to the corresponding session's memories page.
              // in a pairs tournament, each team in this view is from a different session.
              onClick={isAdmin ? handleSelectPairsTeam : undefined}
            />
          ) : (
            <ScoreboardAlbum
              session={session}
              renderer={renderer}
              // allows the admin to select a team and view their souvenirs. this is available for the scoreboard
              // album because all the teams in this album were a part of _this_ session.
              onClick={isAdmin ? stableSetSelectedTeam : undefined}
            />
          )}
        </section>
      </div>
    </Layout>
  );
}

// eslint-disable-next-line import/no-default-export
export default function Memories(): JSX.Element | null {
  const { sessionId } = useParams<'sessionId'>();
  useUser({ init: true });
  const isUserLoaded = useIsUserLoaded();

  if (!sessionId) {
    return null;
  }

  if (!isUserLoaded) {
    return <GlobalLoading />;
  }

  return <MemoriesContainer sessionId={sessionId} />;
}
